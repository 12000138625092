import React, { useState, useEffect } from 'react';
import ButtonDropdown from '@amzn/awsui-components-react/polaris/button-dropdown';
import Spinner from '@amzn/awsui-components-react/polaris/spinner';
import {
  ListVenuesCommand,
  ListVenuesCommandOutput,
  ListVenuesInput,
  VenueInfoItem,
} from '@amzn/awsdev-docs-virtual-smiley-conference-typescript-client';
import { NoTokenError } from '../errors/NoTokenError';
import { callApi } from '../client';
import { useAuthState } from '../authentication/context';
import { Alert } from '@amzn/awsui-components-react';

interface VenueInfo {
  venue: string;
  conference: string;
}

interface VenueDropdownProps {
  onVenueSelect: (venueInfo: VenueInfo) => void;
}

export const VenueDropdown: React.FC<VenueDropdownProps> = ({
  onVenueSelect,
}) => {
  const [venues, setVenues] = useState<VenueInfoItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [fetchSuccess, setFetchSuccess] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');
  const { token, isAuthenticated } = useAuthState();

  useEffect(() => {
    if (token) {
      fetchVenues();
    }
  }, [token]);

  async function fetchVenues() {
    if (!token) throw new NoTokenError();

    try {
      setIsLoading(true);
      const input: ListVenuesInput = {};
      const command = new ListVenuesCommand(input);
      let response: ListVenuesCommandOutput = await callApi(command, token);

      if (response.$metadata.httpStatusCode === 200) {
        setFetchSuccess(true);
        if (response.venues) {
          const sortedVenues = [...response.venues].sort((a, b) =>
            (a.venue || '').localeCompare(b.venue || '')
          );
          setVenues(sortedVenues);
        }
      } else {
        setFetchSuccess(false);
        setErrorMsg('Failed to fetch venues');
      }
    } catch (err: any) {
      console.error('Error fetching venues:', err);
      setFetchSuccess(false);
      setErrorMsg(err.message || 'An error occurred while fetching venues');
    } finally {
      setIsLoading(false);
    }
  }

  const handleItemClick = (event: { detail: { id: string } }) => {
    const selectedVenue = venues.find((v) => v.venue === event.detail.id);
    if (selectedVenue) {
      onVenueSelect({
        venue: selectedVenue.venue || '',
        conference: selectedVenue.conference || '',
      });
    }
  };

  const dropdownItems = venues.map((venueInfo) => ({
    text: `${venueInfo.venue} (${venueInfo.conference})`,
    id: venueInfo.venue || '',
    disabled: false,
  }));

  if (!fetchSuccess) {
    return (
      <Alert type='error' header='Error loading venues'>
        {errorMsg}
      </Alert>
    );
  }

  return (
    <div style={{ minWidth: '200px' }}>
      {isLoading ? (
        <ButtonDropdown items={[]} disabled={true}>
          <Spinner size='normal' /> Loading venues...
        </ButtonDropdown>
      ) : (
        <ButtonDropdown
          items={dropdownItems}
          onItemClick={handleItemClick}
          expandToViewport={true}
        >
          Select a venue
        </ButtonDropdown>
      )}
    </div>
  );
};
