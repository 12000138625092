import React, { useState, useEffect } from 'react';
import SideNavigation, {
  SideNavigationProps,
} from '@amzn/awsui-components-react/polaris/side-navigation';
import { useLocation } from 'react-router';
import { getSideNavActiveHref } from './utils';
import { useAuthState } from '../../authentication/context';
import {
  GetUserGroupsCommand,
  GetUserGroupsCommandOutput,
} from '@amzn/awsdev-docs-virtual-smiley-conference-typescript-client';
import { callApi } from '../../client';
import { NoTokenError } from '../../errors/NoTokenError';

import Item = SideNavigationProps.Item;

const SideNav: React.FC = () => {
  const [activeHref, setActiveHref] = useState<string>(window.location.hash);
  const [permissions, setPermissions] = useState<string[]>([]);
  const location = useLocation();
  const { isAuthenticated, token } = useAuthState();

  useEffect(() => {
    setActiveHref(getSideNavActiveHref(location.pathname));
  }, [location.pathname]);

  useEffect(() => {
    if (isAuthenticated && token) {
      getUserGroups();
    }
  }, [isAuthenticated, token]);

  const getUserGroups = async () => {
    if (!token) throw new NoTokenError();

    try {
      const command = new GetUserGroupsCommand({});
      const response: GetUserGroupsCommandOutput = await callApi(
        command,
        token
      );

      if (response.$metadata.httpStatusCode === 200) {
        setPermissions(response.permissions || []);
      } else {
        console.error(
          'Failed to fetch user groups. Status code:',
          response.$metadata.httpStatusCode
        );
      }
    } catch (err: any) {
      console.error('Error fetching user groups:', err);
    }
  };

  const getNavigationItems = (): Item[] => {
    const baseItems: Item[] = [
      { type: 'link', text: 'Launch List', href: '#/conferences' },
    ];

    if (permissions.includes('venue-access')) {
      baseItems.push({
        type: 'link',
        text: 'Build to Venue',
        href: '#/venuevsbuild',
      });
    }

    return [
      {
        type: 'section',
        text: 'TCX Launch Coordinator',
        items: baseItems,
      },
    ] as Item[];
  };

  if (!isAuthenticated) {
    return null;
  }

  return (
    <SideNavigation
      activeHref={activeHref}
      header={{ href: '#/', text: 'Virtual Smiley' }}
      onFollow={(event) => {
        setActiveHref(event.detail.href);
      }}
      items={getNavigationItems()}
    />
  );
};

export default SideNav;
