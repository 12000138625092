import { useState } from 'react';
import { useCollection } from '@amzn/awsui-collection-hooks';
import {
  Box,
  Button,
  Pagination,
  Header,
  Table,
  TextFilter,
  SpaceBetween,
  Toggle,
  CollectionPreferences,
} from '@amzn/awsui-components-react/polaris';
import {
  FetchedLaunchData,
  TranslatedWorkStatus,
  TraslatedDocumentStatus,
} from '../../../interfaces';
import {
  LaunchTask,
  WorkStatus,
  BuildRequestStatus,
  UserInfo,
  DocumentationStatus,
  ExportLaunchTasksCommand,
  ExportLaunchTasksCommandOutput,
} from '@amzn/awsdev-docs-virtual-smiley-conference-typescript-client';
import { EmptyState } from '../../ui/EmptyState';
import {
  displayBuildStatus,
  displayPersonAssigned,
  getListOfPackages,
  convertToPST,
} from '../utils';
import { useRouteMatch } from 'react-router-dom';
import { DeleteModal } from './DeleteModal';
import { useAuthState } from '../../../authentication/context';
import { downloadCsv } from '../../../downloadCsv';
import { callApi } from '../../../client';

export const ConferenceListTable = ({ data, loading }: FetchedLaunchData) => {
  //data = mockLaunchTasks
  const { path } = useRouteMatch();
  const [showModal, setShowModal] = useState(false);
  const [selectedItems, setSelectedItems] = useState<LaunchTask[]>([]);
  const [showDeleted, setShowDeleted] = useState(false);
  const [showEmptyWriter, setShowEmptyWriter] = useState(false);
  const SERVICES_COLUMNS = 'conferenceColumns';
  const { token, isAuthenticated } = useAuthState();
  const VISIBLE_COLUMNS = [
    'launchId',
    'conference',
    'venue',
    'launchDate',
    'name',
    'dueDate',
    'status',
    'productName',
    'writerAssignment',
    'modifiedAt',
    'packageBuildInfoList',
    'workStatus',
    'lastBuildDate',
    'lastBuildStatus',
    'editor',
    'documentationStatus',
    'type',
    'lastTimeToBuild',
  ];
  const [preferences, setPreferences] = useState({
    pageSize: 20,
    wrapLines: true,
    visibleContent: VISIBLE_COLUMNS,
  });

  const conferenceColumns = [
    {
      id: 'launchId',
      header: 'Launch ID',
      cell: (s: LaunchTask) => s.launchId || '-',
      sortingField: 'launchId',
    },
    {
      id: 'name',
      header: 'Launch Name',
      cell: (s: LaunchTask) => (s.name ? s.name : '-'),
      sortingField: 'name',
    },
    {
      id: 'type',
      header: 'Launch Type',
      cell: (s: LaunchTask) => (s.type ? s.type : '-'),
      sortingField: 'type',
    },
    {
      id: 'conference',
      header: 'Conference',
      cell: (s: LaunchTask) => s.conference || '-',
      sortingField: 'conference',
    },

    {
      id: 'venue',
      header: 'Venue',
      cell: (s: LaunchTask) => s.venue || '-',
      sortingField: 'venue',
    },
    {
      id: 'launchDate',
      header: 'Launch Date',
      cell: (s: LaunchTask) => {
        if (typeof s.launchDate === 'number') {
          const dateObject = convertToPST(s.launchDate);
          return dateObject;
        } else {
          return '-';
        }
      },
      sortingField: 'launchDate', //PT
    },
    {
      id: 'dueDate',
      header: 'Due Date',
      cell: (s: LaunchTask) => {
        if (s.overdue && typeof s.dueDate === 'number') {
          const dateObject = convertToPST(s.dueDate, false);
          return dateObject;
        } else {
          return '-';
        }
      },
      sortingField: 'dueDate',
    },
    {
      id: 'status',
      header: 'Launch Status',
      cell: (s: LaunchTask) => s.status || '-',
      sortingField: 'status',
    },
    {
      id: 'productName',
      header: 'Product Name',
      cell: (s: LaunchTask) => s.productName || '-',
      sortingField: 'productName',
    },

    {
      id: 'writerAssignment',
      header: 'Writer Assignment',
      cell: (s: LaunchTask) =>
        displayPersonAssigned(s.writerAssignment?.alias) || '-',
      sortingField: 'writerAssignment',
      sortingComparator: (s1: LaunchTask, s2: LaunchTask) => {
        const alias1 = s1.writerAssignment?.alias ?? '';
        const alias2 = s2.writerAssignment?.alias ?? '';

        if (!alias1 && !alias2) {
          return 0;
        }
        if (!alias1) {
          return 1;
        }
        if (!alias2) {
          return -1;
        }
        return alias1.localeCompare(alias2);
      },
    },
    {
      id: 'modifiedAt',
      header: 'Last Updated Date',
      cell: (s: LaunchTask) => {
        if (typeof s.modifiedAt === 'number') {
          const dateObject = convertToPST(s.modifiedAt);
          return dateObject;
        } else {
          return '-';
        }
      },
      sortingField: 'modifiedAt',
    },
    {
      id: 'packageBuildInfoList',
      header: 'Package List',
      cell: (s: LaunchTask) => getListOfPackages(s.packageBuildInfoList) || '-',
      sortingField: 'packageBuildInfoList',
    },
    {
      id: 'workStatus',
      header: 'Work Status',
      cell: (s: LaunchTask) =>
        s.workStatus ? TranslatedWorkStatus[s.workStatus] : '-',
      sortingField: 'workStatus',
    },
    {
      id: 'lastBuildDate',
      header: 'Last Build Date',
      cell: (s: LaunchTask) => {
        if (typeof s.packageBuildInfoModifiedAt === 'number') {
          const dateObject = convertToPST(s.launchDate);
          return dateObject;
        } else {
          return '-';
        }
      },
      sortingField: 'lastBuildDate',
    },
    {
      id: 'lastBuildStatus',
      header: 'Last Build Status',
      cell: (s: LaunchTask) =>
        s.buildRequestStatus
          ? displayBuildStatus(BuildRequestStatus[s.buildRequestStatus])
          : '-',
      sortingField: 'lastBuildStatus',
    },
    {
      id: 'editor',
      header: 'Editor',
      cell: (s: LaunchTask) => s.editor?.alias || '-',
      sortingField: 'editor',
    },
    {
      id: 'documentationStatus',
      header: 'Documentation Status',
      cell: (s: LaunchTask) =>
        s.documentationStatus
          ? TraslatedDocumentStatus[s.documentationStatus]
          : '-',
      sortingField: 'documentationStatus',
    },
    {
      id: 'lastTimeToBuild',
      header: 'Est. Last Time to Build',
      cell: (s: LaunchTask) =>
        'lastTimeToBuild' in s ? convertToPST(s.lastTimeToBuild, true) : '-',
    },
  ];

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const textMatch = (inputText: string, verifyingText: string) => {
    return inputText.toLowerCase().includes(verifyingText.toLowerCase());
  };

  function isWorkStatus(value: any): value is WorkStatus {
    return Object.values(WorkStatus).includes(value);
  }

  function isDocumentStatus(value: any): value is DocumentationStatus {
    return Object.values(DocumentationStatus).includes(value);
  }

  function isBuildRequestStatus(value: any): value is BuildRequestStatus {
    return Object.values(BuildRequestStatus).includes(value);
  }

  //  The useCollection React hook takes original items and a configuration and returns filtered, sorted and paginated content, according to your configuration.
  const {
    items,
    actions,
    filteredItemsCount,
    collectionProps,
    filterProps,
    paginationProps,
  } = useCollection(data || [], {
    filtering: {
      empty: (
        // TODO: Update this empty state to use an Alert component
        <EmptyState
          title='The list of services could not be loaded'
          subtitle='Try refreshing your browser. If that doesn’t work, our team is most likely working on resolving it.'
          action={
            <Button onClick={() => location.reload()}>Refresh browser </Button>
          }
        />
      ),
      noMatch: (
        <EmptyState
          title='No matches'
          subtitle='No services matched the specified filter'
          action={
            <Button onClick={() => actions.setFiltering('')}>
              Clear filter
            </Button>
          }
        />
      ),
      filteringFunction: (launchData, filteringText) => {
        if (!launchData) {
          return false;
        }
        if (launchData.workStatus === 'RESOLVED') {
          return false;
        }
        if (!showDeleted && launchData.workStatus === 'DELETED') {
          return false;
        }

        if (showEmptyWriter && launchData.writerAssignment) {
          return false;
        }

        // Check if any of the VISIBLE_COLUMNS contain the filteringText
        const filteringTextMatched = [...VISIBLE_COLUMNS]
          .filter((key: string) => key in launchData)
          .map((key: string) => launchData[key as keyof typeof launchData])
          .some((value) => {
            switch (typeof value) {
              case 'string':
                // alert(value)
                if (isWorkStatus(value)) {
                  value = TranslatedWorkStatus[value];
                } else if (isDocumentStatus(value)) {
                  value = TraslatedDocumentStatus[value];
                } else if (isBuildRequestStatus(value)) {
                  value = BuildRequestStatus[value];
                } else return textMatch(value, filteringText);
              case 'number':
                if (typeof value === 'number') {
                  const newDate = new Date(value * 1000).toLocaleDateString();

                  return textMatch(newDate as string, filteringText);
                }
                return false;
              case 'object':
                if (Array.isArray(value)) {
                  if (value.length > 0 && typeof value[0] === 'object') {
                    console.log(value);
                    const hasMatchingValue = value.some((item) => {
                      if (typeof item === 'object' && 'packageName' in item) {
                        return textMatch(item.packageName, filteringText);
                      }
                      return false;
                    });
                    return hasMatchingValue;
                  }
                } else {
                  const personAssignedText =
                    typeof value === 'object' && 'alias' in value
                      ? (value as unknown as UserInfo).alias
                      : '';
                  return textMatch(personAssignedText, filteringText);
                }
              default:
                return false;
            }
          });
        return filteringTextMatched;
      },
    },
    pagination: { pageSize: preferences.pageSize },
    sorting: {
      defaultState: {
        sortingColumn: conferenceColumns[0],
        isDescending: false,
      },
    },
    selection: {},
  });

  const exportAll = async () => {
    if (token) {
      try {
        let response: ExportLaunchTasksCommandOutput = await callApi(
          new ExportLaunchTasksCommand({}),
          token
        );

        if (response.$metadata.httpStatusCode == 200) {
          const text = new TextDecoder().decode(response.output);
          downloadCsv(`TLC-export-${+new Date()}`, text);
        } else {
          console.error(
            'Unexpected response status code:',
            response.$metadata.httpStatusCode
          );
        }
      } catch (err: any) {
        console.log('Error with ExportLaunchTasks API', err);
      }
    } else {
      alert("You're not logged in");
    }
  };

  return (
    <Box margin={{ top: 'l', bottom: 'xxl' }}>
      <Table
        {...collectionProps}
        onSelectionChange={({ detail }) => {
          setSelectedItems(detail.selectedItems);
        }}
        selectedItems={selectedItems}
        columnDefinitions={conferenceColumns}
        items={items}
        loadingText='Retrieving launch data'
        loading={loading}
        selectionType='single'
        trackBy='launchId'
        visibleColumns={preferences.visibleContent}
        stickyHeader={true}
        header={
          <Header
            actions={
              <div>
                <SpaceBetween direction='horizontal' size='xs'>
                  <Toggle
                    onChange={({ detail }) => setShowDeleted(detail.checked)}
                    checked={showDeleted}
                  >
                    Show deleted items
                  </Toggle>
                  <Toggle
                    onChange={({ detail }) =>
                      setShowEmptyWriter(detail.checked)
                    }
                    checked={showEmptyWriter}
                  >
                    Tasks without writer assignment
                  </Toggle>
                  <Button
                    //disabling for 1st release
                    //disabled={true}
                    disabled={
                      selectedItems.length === 0 ||
                      selectedItems[0].workStatus === WorkStatus.DELETED
                    }
                    onClick={openModal}
                  >
                    Delete
                  </Button>

                  {showModal && (
                    <DeleteModal
                      launchId={selectedItems[0].launchId}
                      onClose={closeModal}
                    />
                  )}
                  <Button
                    //disabling for 1st release
                    disabled={selectedItems.length === 0}
                    variant='primary'
                    href={`#${path}/view-${
                      selectedItems.length > 0 ? selectedItems[0].launchId : '0'
                    }`}
                  >
                    View details
                  </Button>
                  <Button onClick={exportAll}>Export all</Button>
                </SpaceBetween>
              </div>
            }
            counter={
              data && filteredItemsCount
                ? `(${filteredItemsCount}/${data.length})`
                : `(${items.length})`
            }
          >
            TCX Conferences Tasks
          </Header>
        }
        pagination={<Pagination {...paginationProps} />}
        wrapLines
        filter={
          <div>
            <div>
              <TextFilter
                {...filterProps}
                filteringPlaceholder='Filter by Launch name, Package name and Launch status '
              />
            </div>
          </div>
        }
        preferences={
          <CollectionPreferences
            onConfirm={({ detail }) =>
              setPreferences({
                ...preferences,
                pageSize: detail.pageSize ?? 10,
                visibleContent: detail.visibleContent as string[],
              })
            }
            preferences={preferences}
            visibleContentPreference={{
              title: 'Select visible content',
              options: [
                {
                  label: 'Columns: ',
                  options: [
                    { id: 'launchId', label: 'Launch ID' },
                    { id: 'conference', label: 'Conference' },
                    { id: 'venue', label: 'Venue' },
                    { id: 'dueDate', label: 'Due Date' },
                    { id: 'status', label: 'Status' },
                    { id: 'productName', label: 'Product Name' },
                    { id: 'writerAssignment', label: 'Writer Assignment' },
                    { id: 'lastUpdatedDate', label: 'Last Updated Date' },
                    { id: 'packageList', label: 'Package List' },
                    { id: 'workStatus', label: 'Work Status' },
                    { id: 'lastBuildDate', label: 'Last Build Date' },
                    { id: 'lastBuildStatus', label: 'Last Build Status' },
                    { id: 'editor', label: 'Editor' },
                    {
                      id: 'documentationStatus',
                      label: 'Documentation Status',
                    },
                    { id: 'name', label: 'Launch Name' },
                    { id: 'type', label: 'Launch type' },
                  ],
                },
              ],
            }}
            pageSizePreference={{
              title: 'Select page size',
              options: [
                { value: 20, label: '20 launches' },
                { value: 50, label: '50 launches' },
              ],
            }}
            cancelLabel='Cancel'
            confirmLabel='Confirm'
            title='Preferences'
          />
        }
      />
    </Box>
  );
};
