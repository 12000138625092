import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom';
import { AuthSpinner } from '../authentication/AuthSpinner';
import { useAuthState } from '../authentication/context';
import Home from '../ConferencePages/Home';
import ConferenceList from '../ConferencePages/conferenceInfo/ConferenceList';
import VenueList from '../VenueVSBuildPages/VenueList';
import Error from '../ConferencePages/Error';
import { isDevDesk } from '../ConferencePages/conferenceInfo/utils';

export default function AppRouter() {
  const { isAuthenticated } = useAuthState();

  if (isAuthenticated || isDevDesk()) {
    return (
      <Switch>
        <Route exact path='/' component={Home} />
        <Route path='/conferences' component={ConferenceList} />
        <Route path='/venuevsbuild' component={VenueList} />
        <Route path='/error' component={Error} />
        <Route render={() => <Error />} />
      </Switch>
    );
  } else {
    return <AuthSpinner />;
  }
}
