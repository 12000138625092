import {
  GetManifestDiffCommand,
  GetManifestDiffCommandInput,
  GetManifestDiffCommandOutput,
} from '@amzn/awsdev-docs-virtual-smiley-conference-typescript-client';
import { callApi } from '../client';

export const getManifestDiff = async (
  venue: string,
  conference: string,
  token: string
): Promise<string> => {
  try {
    const input: GetManifestDiffCommandInput = {
      venue: venue,
      conference: conference,
    };

    const command = new GetManifestDiffCommand(input);
    const response: GetManifestDiffCommandOutput = await callApi(
      command,
      token
    );

    if (response.$metadata.httpStatusCode === 200 && response.s3link) {
      return response.s3link;
    }
    throw new Error('Failed to get manifest diff link');
  } catch (error) {
    console.error('Error getting manifest diff:', error);
    throw error;
  }
};
