import {
  BuildVenueCommand,
  BuildVenueCommandInput,
  BuildVenueCommandOutput,
  InvalidInputException,
  ResourceNotFoundException,
  AccessDeniedException,
  TcxRuntimeException,
} from '@amzn/awsdev-docs-virtual-smiley-conference-typescript-client';
import { callApi } from '../client';

export const buildForVenue = async (
  venue: string,
  conference: string,
  token: string
): Promise<void> => {
  try {
    const input: BuildVenueCommandInput = {
      venue: venue,
      conference: conference,
    };

    const command = new BuildVenueCommand(input);
    const response: BuildVenueCommandOutput = await callApi(command, token);
  } catch (error: unknown) {
    if (error instanceof Error) {
      console.error(`Error:`, error.message);
    } else {
      console.error('An unknown error occurred:', error);
    }
    throw new Error('An error occurred while processing your request.');
  }
};
