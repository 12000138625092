import React, { useState } from 'react';
import { VenueDropdown } from './GetVenues';
import { buildForVenue } from './BuildService';
import { getManifestDiff } from './ManifestService';
import { useAuthState } from '../authentication/context';
import {
  Button,
  Container,
  Header,
  SpaceBetween,
  Grid,
  Box,
  Alert,
  Modal,
  Link,
  TextContent,
} from '@amzn/awsui-components-react';

interface VenueInfo {
  venue: string;
  conference: string;
}

export const VenueList: React.FC = () => {
  const [selectedVenue, setSelectedVenue] = useState<VenueInfo | null>(null);
  const [isBuilding, setIsBuilding] = useState<boolean>(false);
  const [buildError, setBuildError] = useState<string | null>(null);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [showManifestModal, setShowManifestModal] = useState<boolean>(false);
  const [showBuildConfirm, setShowBuildConfirm] = useState<boolean>(false);
  const [manifestLinkClicked, setManifestLinkClicked] =
    useState<boolean>(false);
  const [manifestLink, setManifestLink] = useState<string>('');
  const [isLoadingManifest, setIsLoadingManifest] = useState(false);
  const { token } = useAuthState();

  const handleVenueSelect = (venueInfo: VenueInfo) => {
    setSelectedVenue(venueInfo);
  };

  const handleBuildClick = async () => {
    if (!selectedVenue || !token) return;

    setIsLoadingManifest(true);
    try {
      const link = await getManifestDiff(
        selectedVenue.venue,
        selectedVenue.conference,
        token
      );
      setManifestLink(link);
      setShowManifestModal(true);
    } catch (error) {
      console.error('Failed to get manifest diff:', error);
    } finally {
      setIsLoadingManifest(false);
    }
    setManifestLinkClicked(false);
  };

  const handleManifestConfirm = () => {
    setShowManifestModal(false);
    setShowConfirmation(true);
  };

  const handleConfirmBuild = () => {
    setShowConfirmation(false);
    setShowBuildConfirm(true);
  };

  const handleFinalBuildConfirm = async () => {
    if (!selectedVenue || !token) return;

    setIsBuilding(true);
    setBuildError(null);
    try {
      await buildForVenue(selectedVenue.venue, selectedVenue.conference, token);
    } catch (error) {
      console.error('Build process failed:', error);
      setBuildError(
        error instanceof Error ? error.message : 'An unexpected error occurred'
      );
    } finally {
      setIsBuilding(false);
      setShowBuildConfirm(false);
      setManifestLinkClicked(false);
    }
  };

  const handleCancelBuild = () => {
    setShowConfirmation(false);
    setShowManifestModal(false);
    setManifestLinkClicked(false);
  };

  const handleCloseManifestModal = () => {
    setShowManifestModal(false);
    setManifestLinkClicked(false);
  };

  return (
    <SpaceBetween size='l'>
      <Container
        header={
          <Header variant='h1' description='Select a venue and start building'>
            Welcome to Venue Builder
          </Header>
        }
      >
        <SpaceBetween size='l'>
          <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
            <VenueDropdown onVenueSelect={handleVenueSelect} />
            <Button
              onClick={handleBuildClick}
              disabled={!selectedVenue || isBuilding || isLoadingManifest}
              loading={isBuilding || isLoadingManifest}
              variant='primary'
            >
              {isBuilding
                ? 'Building...'
                : isLoadingManifest
                ? 'Loading Manifest...'
                : 'Build'}
            </Button>
          </Grid>

          {selectedVenue && (
            <Container
              header={<Header variant='h2'>Selected Venue</Header>}
              footer={
                <Box
                  color='text-status-info'
                  fontSize='heading-l'
                  fontWeight='bold'
                >
                  {selectedVenue.venue} ({selectedVenue.conference})
                </Box>
              }
            >
              <Box padding='l' textAlign='center'>
                <SpaceBetween size='s'>
                  <Box fontSize='heading-xl' color='text-label'>
                    {selectedVenue.venue}
                  </Box>
                  <Box fontSize='heading-m' color='text-status-info'>
                    Conference: {selectedVenue.conference}
                  </Box>
                </SpaceBetween>
              </Box>
            </Container>
          )}
        </SpaceBetween>
      </Container>

      <Modal
        onDismiss={handleCloseManifestModal}
        visible={showManifestModal}
        header='Manifest Difference Review'
        size='medium'
        footer={
          <Box float='right'>
            <SpaceBetween direction='horizontal' size='xs'>
              <Button variant='link' onClick={handleCloseManifestModal}>
                Cancel
              </Button>
              <Button
                variant='primary'
                onClick={handleManifestConfirm}
                disabled={!manifestLinkClicked}
              >
                Proceed with Build
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <SpaceBetween size='l'>
          <Container>
            <SpaceBetween size='m'>
              <Box textAlign='center'>
                <SpaceBetween size='xxs'>
                  <Header
                    variant='h1'
                    description='Please review the manifest difference before proceeding'
                  >
                    {selectedVenue?.venue}
                  </Header>
                </SpaceBetween>
              </Box>

              <Alert
                type='warning'
                header='Loading Issues?'
                dismissible={false}
              >
                If the manifest difference does not load, please refresh the
                manifest difference link and try again.
              </Alert>

              <Box>
                <SpaceBetween size='s'>
                  <TextContent>
                    <ol>
                      <li>
                        Click the link below to review the manifest difference
                      </li>
                      <li>Confirm that you have reviewed the changes</li>
                    </ol>
                  </TextContent>

                  <Box textAlign='center'>
                    <Link
                      href={manifestLink}
                      external
                      onFollow={() => setManifestLinkClicked(true)}
                      fontSize='heading-m'
                    >
                      Open Manifest Difference
                    </Link>
                  </Box>

                  <Box textAlign='center'>
                    <SpaceBetween size='s'>
                      <TextContent>
                        <p>
                          <strong>
                            Have you reviewed the manifest difference?
                          </strong>
                        </p>
                      </TextContent>
                      <Button
                        onClick={() => setManifestLinkClicked(true)}
                        disabled={manifestLinkClicked}
                        variant='primary'
                      >
                        Yes, I have reviewed the changes
                      </Button>
                    </SpaceBetween>
                  </Box>
                </SpaceBetween>
              </Box>
            </SpaceBetween>
          </Container>
        </SpaceBetween>
      </Modal>

      {showConfirmation && (
        <Alert
          onDismiss={handleCancelBuild}
          header='Build Confirmation'
          type='info'
          action={
            <SpaceBetween direction='horizontal' size='xs'>
              <Button onClick={handleConfirmBuild} variant='primary'>
                Confirm
              </Button>
              <Button onClick={handleCancelBuild} variant='link'>
                Cancel
              </Button>
            </SpaceBetween>
          }
        >
          Are you sure you want to build for {selectedVenue?.venue}?
        </Alert>
      )}

      {showBuildConfirm && (
        <Alert
          onDismiss={() => setShowBuildConfirm(false)}
          header='Final Build Confirmation'
          type='warning'
          action={
            <SpaceBetween direction='horizontal' size='xs'>
              <Button onClick={handleFinalBuildConfirm} variant='primary'>
                Confirm Build
              </Button>
              <Button onClick={() => setShowBuildConfirm(false)} variant='link'>
                Cancel
              </Button>
            </SpaceBetween>
          }
        >
          Confirm build deployment for {selectedVenue?.venue}?
        </Alert>
      )}
    </SpaceBetween>
  );
};

export default VenueList;
